
import React from 'react';

export default function LinkedIn(props: any) {
    return(
        <svg width="40" height="40" viewBox="0 0 40 40" fill="current" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4243_13833)">
            <path d="M24.1275 11.6978H15.8724C13.1857 11.6978 11 13.8835 11 16.5702V24.8254C11 27.512 13.1857 29.6977 15.8724 29.6977H24.1277C26.8142 29.6977 29 27.512 29 24.8254V16.5702C29 13.8835 26.8142 11.6978 24.1275 11.6978V11.6978ZM20 25.6195C17.2861 25.6195 15.0783 23.4116 15.0783 20.6977C15.0783 17.9838 17.2861 15.776 20 15.776C22.7139 15.776 24.9217 17.9838 24.9217 20.6977C24.9217 23.4116 22.7139 25.6195 20 25.6195ZM25.0394 16.9367C24.2374 16.9367 23.5851 16.2844 23.5851 15.4824C23.5851 14.6804 24.2374 14.0279 25.0394 14.0279C25.8414 14.0279 26.4939 14.6804 26.4939 15.4824C26.4939 16.2844 25.8414 16.9367 25.0394 16.9367Z" fill="current"/>
            <path d="M19.9998 16.8312C17.8679 16.8312 16.1333 18.5656 16.1333 20.6977C16.1333 22.8296 17.8679 24.5642 19.9998 24.5642C22.1318 24.5642 23.8663 22.8296 23.8663 20.6977C23.8663 18.5656 22.1318 16.8312 19.9998 16.8312Z" fill="current"/>
            <path d="M25.0392 15.0833C24.8192 15.0833 24.6401 15.2623 24.6401 15.4823C24.6401 15.7023 24.8192 15.8814 25.0392 15.8814C25.2594 15.8814 25.4384 15.7025 25.4384 15.4823C25.4384 15.2622 25.2594 15.0833 25.0392 15.0833Z" fill="current"/>
            </g>
            <defs>
            <clipPath id="clip0_4243_13833">
            <rect width="18" height="18" fill="white" transform="translate(11 11.6978)"/>
            </clipPath>
            </defs>
        </svg>
    )
}