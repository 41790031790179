import feature1 from '../../assets/svgs/feature-01.svg';
import feature2 from '../../assets/svgs/feature-02.svg';
import feature3 from '../../assets/svgs/feature-03.svg';
import DiagonalArrow from '../../assets/svgs/diagonal-arrow.svg';
import '../../index.css';
import { ToastOptions } from '../../types';

function ShowcaseSection({notify}: ToastOptions) {
  return (
    <div className='px-4 mt-[69px] pb-[80px] m-auto max-w-[1450px] sm:px-12 lg:px-18 xl:px-32'>
      <div className='flex flex-col w-full sm:flex-row sm:justify-between'>
        <div className='sm:w-2/5 xl:w-[50%]'>
          <p className='mobile-manual-bold-text text-[30px] leading-[40px] text-text-primary font-sfPro font-semibold text-left xl:leading-[93px] xl:text-[78px] lg:leading-[70px] lg:text-[60px] md:text-[40px] sm:leading-[60px]'>
            Never pay for <span className='font-merienda sm:font-normal xl:text-[69px]'>anything</span> online without using Paycrow.
          </p>
        </div>
        <div className='relative sm:w-2/5 sm:top-[100px] lg:top-[120px]'>
          <img src={feature1} alt='denim-shirt' className='showcase-img w-full mt-5 h-full sm:h-auto' />
          <p className='text-text-primary text-2xl mobile-manual-bold-text mb-2 font-sfPro font-bold text-lg mt-6'>Pay vendors and freelancers</p>
          <p className='text-text-secondary font-sfPro font-normal text-base'>
            Pay anyone no matter where you met them with peace of mind. The boundaries of who you transact with is limitless.
          </p>
          <a onClick={notify} className='mt-5 font-normal text-base text-button-light'>
            <div className='mt-5 font-normal font-sfPro text-base mb-5 flex'>
              Learn more
              <img alt='diagonal-arrow' src={DiagonalArrow} className='ml-2' />
            </div>
          </a>
        </div>
      </div>
            
      <div className='flex flex-col w-full sm:flex-row sm:justify-between'>
        <div className='relative sm:w-2/5 sm:bottom-[180px]'>
          <img src={feature2} alt='fresh-fruits' className='showcase-img w-full mt-5 h-full sm:h-auto' />
          <p className='text-text-primary text-2xl mobile-manual-bold-text mb-2 font-sfPro font-bold text-lg mt-6 sm:hidden'>Get paid the right way</p>
          <p className='text-text-secondary font-sfPro font-normal text-base sm:hidden'>
          Skip the sales pitch. Communicate quality and trust by offering your customers more control over their money.
          </p>
          <p className='text-text-primary text-2xl mobile-manual-bold-text mb-2 font-sfPro font-bold text-lg mt-6 hidden sm:block'>Stand out from other merchants with Paycrow.</p>
          <p className='text-text-secondary font-sfPro font-normal text-base hidden sm:block'>
            Skip the sales pitch. Communicate quality and trust by offering your customers more control over their money.
          </p>
          <a  onClick={notify} className='mt-5 font-normal text-base text-button-light'>
            <div className='mt-5 font-normal font-sfPro text-base mb-5 flex'>
              Learn more
              <img alt='diagonal-arrow' src={DiagonalArrow} className='ml-2' />
            </div>
          </a>
        </div>

        <div className='relative lg:mt-[37%] sm:w-2/5 sm:mt-[33%]'>
          <img src={feature3} alt='cement' className='showcase-img w-full mt-5 h-full sm:h-auto' />
          <p className='text-text-primary text-2xl mobile-manual-bold-text mb-2 font-sfPro font-bold text-lg mt-6'>Dropshipping? We got you!</p>
          <p className='text-text-secondary font-sfPro font-normal text-base' >
            Forward payments from your customer to your supplier and keep your profits, all in one app.
          </p>
          <a  onClick={notify} className='mt-5 font-normal text-base text-button-light'>
            <div className='mt-5 font-normal font-sfPro text-base mb-5 flex'>
              Learn more
              <img alt='diagonal-arrow' src={DiagonalArrow} className='ml-2' />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ShowcaseSection;
