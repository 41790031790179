import { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import toast, { Toaster } from 'react-hot-toast';
import {Hero, Navbar, Footer, DetailsSection, ShowcaseSection, JoinWaitlistModal} from '../../sections';

function Home() {
  const [isShown, setIsShown] = useState<boolean>(false);
  const [apiRes, setApiRes] = useState({
    current_priority: 0,
    total_users: 0,
    referral_link: 'https://link.com',
    suggestion: '',
  })
  const toggle = () => setIsShown(!isShown);
  const notify = () => toast('More info coming soon, please join the waitlist to stay updated.',{ icon: '😊'});
  const errorToast = () => toast.error('Something went wrong, please contact support');

  const setGA = () => {
    ReactGA.initialize('G-H1NSJ557ZY');
    ReactGA.pageview('Init page view');
  };
  useEffect(() => {
    setGA();
  }, [])

  
  return (
    <>
      <div className="relative">
        <div className="bg-extended-Y200">
          {/* <Navbar /> */}
          <Hero toggle={toggle} errorToast={errorToast} setApiRes={setApiRes} />
        </div>
        <DetailsSection notify={notify} />
        <ShowcaseSection notify={notify} />
        <Footer toggle={toggle} errorToast={errorToast} setApiRes={setApiRes}/>
      </div>
      <JoinWaitlistModal 
        current_priority={apiRes.current_priority} 
        total_users={apiRes.total_users} 
        referral_link={apiRes.referral_link}
        suggestion={apiRes.suggestion}  
        isShown={isShown} 
        hide={toggle}
      />
      <Toaster />
    </>
  );
}

export default Home;
