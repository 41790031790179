import { createRef, useEffect } from 'react';
import heroImg from '../../assets/svgs/hero-img.svg';
import merch from '../../assets/svgs/merch.svg';
import delivery from '../../assets/svgs/delivery.svg';
import {EmailInputField} from '../../components';
import Navbar from '../navbar';
import { EmailInputOptions } from '../../types';

export default function Index({toggle, setApiRes, errorToast}: EmailInputOptions) {
  const imageDiv = {
    backgroundImage: `url(${heroImg})`,
    minWidth: '50vw',
  };

  const heroDiv = {
    // height: '100vh'
  }

  const heroText = {
    // margin: 'auto'
  }

  const inputRef = createRef<HTMLInputElement>();

  const focus = () => {
    const node = inputRef?.current;

    if (node) {
      node.focus();
    }
  }

  return (
    <>
    <Navbar handleCTAClick={() => {
      focus();
    }}/>
    <div id="home-hero" className="">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 w-full max-w-full 2xl:max-w-[1450px] mx-auto" style={heroDiv}>
        <div className="pt-36 lg:pt-44 pb-16 lg:pb-24" style={heroText}>
          <h1 className='mobile-manual-bold-text px-4 sm:px-12 lg:px-18 xl:px-32 text-[34px] lg:text-[70px] leading-[41px] lg:leading-[80px] font-[600] mb-8 text-text-primary '>
            Your only safe way to pay for <span className='font-merienda font-extralight'>anything.</span>
          </h1>

          <div className="flex lg:hidden bg-cover bg-center h-[403px] mb-4" style={imageDiv}>
          </div>
          <p className='hero-desc text-[20px] lg:text-[26px] leading-[24px] lg:leading-[140%] px-4 sm:px-12 lg:px-18 xl:px-32 mb-[34px] lg:mb-[72px] text-text-primary font-sfPro'>
            Paycrow acts as a middleman in transactions and hold funds securely until you are satisfied with what you paid for.
          </p>

          <div className="px-4 sm:px-12 lg:px-18 xl:px-32 mb-[27px] lg:mb-[47px]">
            <EmailInputField errorToast={errorToast} inputRef={inputRef} toggle={toggle} setApiRes={setApiRes} />
          </div>
          {/* <div className="flex px-4 sm:px-12 lg:px-18 xl:px-32 font-sfPro">
            <div className="mb-2 lg:mb-4 mr-[24px]">
              <div className="flex">
                <img src={merch} alt="" className='incentive-icon mr-[12px] h-[40px] 2xl:h-[58px]'/>
                <div className="text-text-primary">
                  <p className='font-bold text-[14px] sm:text-[16px] 2xl:text-[20px]'>Get free merch</p>
                  <p className="text-[10px] sm:text-[12px] 2xl:text-[16px]">Clothes, caps, bottles</p>
                </div>
              </div>
            </div>

            <div className="mb-2 lg:mb-4">
              <div className="flex">
                <img src={delivery} alt="" className='incentive-icon mr-[12px] h-[40px] 2xl:h-[58px]' />
                <div className="text-text-primary">
                  <p className='font-bold text-[14px] sm:text-[16px] 2xl:text-[20px]'>Get free delivery</p>
                  <p className="text-[10px] sm:text-[12px] 2xl:text-[16px]">Up to 30 free deliveries</p>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <div className="hidden lg:flex bg-cover bg-center lg:m-h-[992px]" style={imageDiv}>
        </div>
      </div>
    </div>
    </>
  )
}
