import React from 'react';

export default function Twitter(props: any) {
    return(
        <svg fill="current" className='w-[40px] h-[40px] flex-inline mr-[16px]' width="40" height="41" viewBox="0 0 40 41" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.5014 13.7751C30.1558 13.9283 29.8004 14.0576 29.4374 14.1625C29.8672 13.6767 30.195 13.1051 30.395 12.4796C30.4399 12.3394 30.3934 12.1859 30.2781 12.0942C30.1629 12.0024 30.0028 11.9914 29.876 12.0665C29.105 12.5235 28.2731 12.852 27.4008 13.044C26.5222 12.186 25.3288 11.6978 24.0951 11.6978C21.4909 11.6978 19.3723 13.8149 19.3723 16.4173C19.3723 16.6222 19.3853 16.8261 19.4109 17.0271C16.1794 16.7435 13.1752 15.1563 11.1122 12.6273C11.0386 12.5372 10.9254 12.4886 10.8094 12.4979C10.6934 12.507 10.5891 12.5723 10.5305 12.6728C10.1121 13.3903 9.89087 14.211 9.89087 15.0459C9.89087 16.1831 10.2972 17.2621 11.0149 18.1052C10.7966 18.0297 10.5849 17.9353 10.3827 17.8231C10.2742 17.7628 10.1418 17.7637 10.034 17.8255C9.92614 17.8873 9.85851 18.0009 9.85567 18.125C9.85517 18.146 9.85517 18.1669 9.85517 18.1881C9.85517 19.8856 10.7694 21.4138 12.1671 22.2468C12.047 22.2348 11.927 22.2174 11.8078 22.1947C11.6849 22.1712 11.5585 22.2142 11.4756 22.3079C11.3925 22.4015 11.3651 22.532 11.4033 22.6512C11.9207 24.2653 13.2527 25.4526 14.8629 25.8146C13.5274 26.6505 12.0001 27.0884 10.3968 27.0884C10.0623 27.0884 9.7258 27.0687 9.39651 27.0298C9.23293 27.0104 9.07652 27.1069 9.02081 27.2623C8.9651 27.4179 9.02414 27.5913 9.16331 27.6804C11.223 29.0002 13.6046 29.6978 16.0505 29.6978C20.8588 29.6978 23.8668 27.4318 25.5434 25.531C27.6341 23.1607 28.8332 20.0234 28.8332 16.9236C28.8332 16.7941 28.8312 16.6633 28.8272 16.533C29.6521 15.9119 30.3622 15.1603 30.9401 14.2964C31.0279 14.1652 31.0184 13.9918 30.9167 13.871C30.8151 13.7502 30.6459 13.7111 30.5014 13.7751Z" fill="current"/>
        </svg>
    )
}

