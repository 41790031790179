import {useState, useEffect} from 'react';
import nigerianFlag from '../../assets/svgs/nigeria-flag.svg';
import dropdownIcon from '../../assets/svgs/dropdown.svg';
import { EmailInputField, InstagramIcon, FacebookIcon, LinkedInIcon, TwitterIcon } from '../../components';
import logo from '../../assets/svgs/logo-small.svg';
import { EmailInputOptions } from '../../types';
import { Link } from 'react-router-dom';


export default function Footer({toggle, setApiRes, errorToast}: EmailInputOptions) {

  const [totalSignups, setTotalSignups] = useState('other');

  useEffect(() => { 
    fetch('https://app.viral-loops.com/api/v3/campaignStats', {
      headers: {
        apiToken: 'NXnGw5dFo9cZv7Ycf7M7wRHugjU',
      },
    })
    .then((res) => res.json())
    .then((data) => {   
      const total = Math.ceil(data.leadCount / 10) * 10;
      setTotalSignups(`${total}+`);
    })
    .catch(err => console.log('err', err))
  },[])

  
  return (
    <footer className='flex flex-col w-full bg-background pt-[76px] md:pt-[156px]'>
      <div className='w-full max-w-full xl:max-w-[1450px] mx-auto px-4 sm:px-12 lg:px-18 xl:px-32'>
        <h1 className='self-center text-center font-sfPro text-text-primary font-bold leading-[40px] md:leading-[91px] mb-[62px] text-[34px] md:text-[76px]'>Join {totalSignups} customers<br /> on the queue.</h1>
        <div className="w-full md:w-[462px] mx-auto">
          <EmailInputField errorToast={errorToast} toggle={toggle}  setApiRes={setApiRes} />
        </div>
        <section className='md:flex flex-col mt-[120px] md:mt-[253px] mb-[120px] md:mb-[202px] w-full self-center'>
          <div className='flex items-start flex-col md:flex-row justify-between'>
            <img alt='diagonal-arrow' src={logo} className='mt-[14px]' style={{width: '60px'}} />

          {/* © 2022 Paycrow Technologies Inc. */}
            {/* <p className="font-sfPro lg:text-[18px] leading-[21px] text-grey text-left mr-[24px] mt-[34px] w-[341px] lg:w-[448px]">Paycrow, Inc. is your new, secured form of payment for all social commerce transactions. Paycrow is a duly-compliant escrow solution, registered with the CAC (RC: 1319469)</p> */}
            <nav className='text-left mr-[24px]'>
              <h6 className='font-sfPro font-[600] text-[20px] leading-[24px] text-text-primary mt-[67px] md:mt-[21px] mb-[16px]'>Company</h6>
              <ul>
                <a href='https://medium.com/paycrows-blog' target="_blank"><li className='mb-[16px] font-sfPro font-[18px] leading-[21px] text-grey'>Blog</li></a>
                <Link to='#'><li className='mb-[16px] font-sfPro font-[18px] leading-[21px] text-grey'>Developers</li></Link>
              </ul>
            </nav>
            <section className='text-left'>
              <h6 className='font-sfPro font-[600] text-[20px] leading-[24px] text-text-primary mt-[21px] mb-[16px]'>Contact</h6>
              <a href="mailto:hello@paycrow.io" className='font-sfPro lg:text-[18px] leading-[21px] text-grey my-[16px]'>hello@paycrow.io</a>
              <div className='flex mt-[14px] flex-row social-icons-row'>
                <a target="_blank" href='https://twitter.com/paycrowhq' className='fill-grey hover:fill-button-light border-grey hover:border-button-light border-[1px] rounded-full w-[40px] h-[40px] mr-[16px]' rel="noreferrer">
                  <TwitterIcon />
                </a>
                <a target="_blank" href='https://www.linkedin.com/company/paycrow/about/' className='flex items-center fill-grey hover:fill-button-light border-grey hover:border-button-light border-[1px] rounded-full w-[40px] h-[40px] mr-[16px]' rel="noreferrer">
                  <LinkedInIcon />
                </a>
                <a target="_blank" href='https://www.instagram.com/paycrowhq/' className='flex items-center fill-grey hover:fill-button-light border-grey hover:border-button-light border-[1px] rounded-full w-[40px] h-[40px] mr-[16px]' rel="noreferrer">
                  <InstagramIcon />
                </a>
                <a target="_blank" href='https://www.facebook.com/paycrowHQ/' className='flex items-center fill-grey hover:fill-button-light border-grey hover:border-button-light border-[1px] rounded-full w-[40px] h-[40px] mr-[16px]' rel="noreferrer">
                  <FacebookIcon />
                </a>
              </div>
            </section>
          </div>
        </section>
        <section className='flex flex-col pb-[77px] md:pb-[122px] self-center'>
          <div className='hidden md:flex flex flex-row items-center'>
            <img className='w-[28px] h-[28px]' alt="flag" src={nigerianFlag} />
            <p className='font-sfPro font-[500] lg:text-[18px] text-grey ml-[11px] mr-[7px]'>Nigeria</p>
            <img className='w-[8px] h-[8px]' alt='dropdown' src={dropdownIcon} />
          </div>
          <div className='flex flex-row text-left md:mt-[46px] mb-[24px] flex-wrap'>
            <p className='font-sfPro font-[600] lg:text-[18px] leading-[21px] text-grey mr-[32px] inline-flex'>© 2022 Paycrow, Inc.</p>
            <ul className='flex flex-row flex-wrap mt-[15px] 633:mt-[0px]'>
              <Link to='#'>
                <li className='font-sfPro lg:text-[18px] leading-[21px] text-grey mr-[32px]'>Privacy Policy</li>
              </Link>
              <Link to='#'>
                <li className='font-sfPro lg:text-[18px] leading-[21px] text-grey mr-[32px]'>Terms of use</li>
              </Link>
              <Link to='#'>
                <li className='font-sfPro lg:text-[18px] leading-[21px] text-grey mr-[32px]'>Legal Agreement</li>
              </Link>
            </ul>
          </div>
          {/* <p className='font-sfPro lg:text-[18px] leading-[21px] text-grey text-left'>
            Paycrow is a licensed escrow solution, duly registered with CAC with RC Number: 1319469.   Paycrow is the leading and most secured form of payment for any transaction you do online. To ensure safety of every transaction, we hold the money that you pay in a seperate commercial bank, this means the  money in your account belongs to you until a transaction is completed.
          </p> */}
        </section>
      </div>
    </footer>
  )
}
