import React from 'react';

export default function LinkedIn(props: any) {
    return(
        <svg width="40" height="40" viewBox="0 0 40 40" fill="current" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.6002 12.6117C12.3628 12.6117 11.5537 13.4242 11.5537 14.4922C11.5537 15.5366 12.3386 16.3723 13.5527 16.3723H13.5762C14.8378 16.3723 15.6229 15.5366 15.6229 14.4922C15.5993 13.4242 14.8378 12.6117 13.6002 12.6117Z" fill="current"/>
            <path d="M11.7676 17.8584H15.385V28.7416H11.7676V17.8584Z" fill="current"/>
            <path d="M24.2643 17.6029C22.3129 17.6029 21.0043 19.4367 21.0043 19.4367V17.8583H17.3867V28.7416H21.0041V22.664C21.0041 22.3386 21.0277 22.0138 21.1232 21.7811C21.3847 21.1314 21.9799 20.4583 22.9792 20.4583C24.2882 20.4583 24.8118 21.4563 24.8118 22.9194V28.7416H28.4289V22.5013C28.4289 19.1585 26.6442 17.6029 24.2643 17.6029Z" fill="current"/>
        </svg>
    )
}