import ReactDOM from 'react-dom';
import { useState } from 'react';

import close from '../../assets/svgs/close.svg';
import facebook from '../../assets/svgs/facebook-share.svg';
import twitter from '../../assets/svgs/twitter-share.svg';
import copy from '../../assets/svgs/copy-share.svg';

import '../../index.css';


function JoinWaitlistModal({
  current_priority, 
  total_users, 
  referral_link, 
  isShown,
  hide,
  suggestion,
  }: Options) {
    const [copyBtn, setCopyBtn] = useState<string>('Copy text');

    const shareText = `I decided to try out Paycrow to avoid being scammed online. Please join with my referral link so we can both get early access, free merchs🎁 and deliveries📦.`
    const copyToClipboard = () => {
      navigator.clipboard.writeText(`${shareText} ${referral_link}`);
      setCopyBtn('Copied!')
    };

    const tweetShare = () => {
      let link = `https://twitter.com/intent/tweet?url=${referral_link}&text=${shareText}&via=paycrowhq&original_referer=https://paycrow.io`;
      window.open(link, "_blank")
    }

    const fbShare = () => {
      let link = "https://www.facebook.com/sharer/sharer.php?u=" + referral_link;
      window.open(link, "_blank")
    }

    const modal = (
      <div className='waitlist-wrapper'>
        <div className='waitlist-main-modal-section'>
          <button className='modal-close' onClick={hide}><img src={close} alt='close' /></button>
          <div className='waitlist-modal'>
            <h2 className='modal-title'>Yay, we added you to the queue🎉</h2>
            <p className='modal-desc'><span className='highlight'>{current_priority}</span> {current_priority <= 1 ? 'person is' : 'people are'} ahead of you out of a total of <span className='highlight'>{total_users}</span> on the waitlist.</p>
            <div className='modal-more-info'>
              <h3>Do you want priority access, free merch🎁 and  delivery📦?</h3>
              <p>{suggestion}. When we launch, <b>the top 50</b> people on the queue gets priority access, free merchs or free deliveries.</p>
              <div className='modal-share-flex'>
                <button  className='modal-social-btn' onClick={tweetShare}>
                  <img src={twitter} alt='twitter' />
                  Tweet
                </button>
                <button  className='modal-social-btn' onClick={fbShare}>
                  <img src={facebook} alt='facebook' />
                  Share
                </button>
                <button className='modal-copy-btn' onClick={copyToClipboard}>
                  {/* add toast after clicking copy */}
                  <img src={copy} alt='copy' />
                  {copyBtn}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  )

  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
}

interface Options {
  current_priority: number;
  total_users: number;
  referral_link: string;
  isShown: boolean;
  hide: () => void;
  suggestion?: string;
}

export default JoinWaitlistModal;