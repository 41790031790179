import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { NavBarProps } from '../../types';

import logo from '../../assets/svgs/logo.svg';


export default function Navbar({ handleCTAClick }: NavBarProps) {
  const [bgTransparency, setBgTransparency] = useState(true);

  useEffect(() => {
    const listener = () => {
      const navbar = document.getElementById('navigation');
      const navbarHeight : number | undefined = navbar?.getBoundingClientRect().height || 0;

      if (window.scrollY > navbarHeight) {
        setBgTransparency(false);
      } else {
        setBgTransparency(true);
      }
    };
    window.addEventListener("scroll", listener);

    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, []);

  return (
    <nav id="navigation" className={`fixed flex items-center w-full top-0 left-0 py-[18px] mx-auto mb-[-8rem] z-10 font-sfPro ${bgTransparency ? "bg-transparent" : "bg-nav-background drop-shadow"}`}>
      <div className="grid grid-cols-2 px-4 sm:px-12 lg:px-18 xl:px-32 w-full max-w-full xl:max-w-[1450px] mx-auto">
        <div className="flex items-center">
          <img alt='diagonal-arrow' src={logo} style={{width: '100px'}}  className="navbar-logo" />
          {/* <h3 className="text-[18px] lg:text-[23px] font-bold text-text-primary mr-8">
            Paycrow
          </h3> */}

          {/* <div className="hidden md:flex space-x-4 text-gray-900">
            <Link to="#" className='text-text-primary'>Developers</Link>
            <Link to="#" className='text-text-primary'>Blog</Link>
          </div> */}
        </div>

        <div className="flex justify-end space-x-2">
          <Link onClick={handleCTAClick} to="#" className="nav-btn px-[32px] lg:px-[38px] py-[14.5px] lg:py-[20px] text-text-primary-light text-[14px] lg:text-[18px] leading-[17px] lg:leading-[21px] bg-button-light hover:bg-button-bg rounded-[74px] h-[46px] lg:h-[62px]">
            Get early access
          </Link>
        </div>
      </div>
    </nav>
  )
}
