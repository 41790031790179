import { useState } from 'react';
import { EmailInputOptions } from '../../types';
import blacklist from '../../utils/emailBlacklist';



export default function EmailInputField({toggle, setApiRes, inputRef, errorToast}: EmailInputOptions){

    const [email, setEmail] = useState('');
    const [loading, setloading] = useState(false);
    const [isValid, setisValid] = useState(false);
    const [showError, setshowError] = useState(false);
    const [btnClicked, setbtnClicked] = useState(false);


    const requestAccess = (email: string) => {
      // @ts-ignore
      window.VL.createLead().then((submittedData: any) => {
        // @ts-ignore
        window.VL.loadRankAndSuggestion(VL, (err, {
          // @ts-ignore
          rank, entries, suggestion, suggestionText
      }) => {
          if (err) console.warn(err);

          fetch('https://app.viral-loops.com/api/v3/campaignStats?apiToken=NXnGw5dFo9cZv7Ycf7M7wRHugjU')
          .then((res) => res.json())
          .then((response) => {
            const data = {
              current_priority: rank - 1,
              // @ts-ignore
              total_users: response.leadCount,
              referral_link: `${window.location.href}?referralCode=${submittedData.referralCode}`,
              // @ts-ignore
              suggestion: window.VL.options.lead.suggestionText,
            };
            setApiRes(data)
            toggle()
            setloading(false)
          })
          .catch((err: any) => {
            setloading(false) 
            errorToast();
            console.log('err', err)
          })
        });
      })
      .catch((err: any) => {
        setloading(false) 
        errorToast();
        console.log('err', err)
      });
  }

    const onInputChange = (e: any) => {
      if (e.target.value !== "undefined") {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      
        if (!pattern.test(e.target.value)) {
          setisValid(false);

          // only show error after the user has clicked on the button once
          if (btnClicked) setshowError(true);
        } else if (pattern.test(e.target.value) && blacklist.includes(e.target.value.split('@')[1])) {
          setisValid(false);
          setshowError(true);
        } else {
          setisValid(true);
          setshowError(false)
        }
      }
      setEmail(e.target.value);
      // @ts-ignore
      window.VL.options.form_fields.form_email = e.target.value;
    }

    return (
      <>
        <div className='hero-btn-wrapper flex flex-row justify-between self-center bg-white rounded-[90px] h-[56px] md:h-[72px] w-[100%] border border-text-secondary border-solid items-center'>
          <input 
            onChange={onInputChange}
            ref={inputRef}
            className='flex-grow h-[52px] pr-[4px] pl-[21px] md:pl-[41px] md:h-[68px] rounded-l-[90px] self-center justify-center box-border text-secondary font-sfPro text-[16px] md:text-[20px] leading-[24px] focus:outline-none' type='email' placeholder='Enter your email' />
          <button 
            disabled={loading}
            onClick={() => {
              setbtnClicked(true);
              // only make api call if email is valid
              if (isValid) {
                setloading(true) 
                requestAccess(email)
              } else setshowError(true)
            }}
            className={`flex flex-none items-center bg-button-light hover:bg-button-bg rounded-[74px] py-[14.5px] md:py-[20px] px-[18px] md:px-[18px] mx-[4px] md:mx-[6px] h-[46px] md:h-[62px] justify-self-end font-sfPro font-[500] text-[14px] md:text-[18px] leading-[21px] text-center text-white`}>
              {loading ? (
                <>
                  <div className="lds-ripple"><div></div><div></div></div>
                  Loading..
                </>) : 'Get early access'}
          </button>
        </div>
        <button className="display-none vrlps-trigger"></button>
       <p className='email-error'> {showError && 'Please enter valid email address.'}</p>
      </>
    )
}

