import React from "react";
import money_plus_safety from "../../assets/svgs/money_plus_safety.svg";
import { ToastOptions } from '../../types';

const DetailSection = ({notify}: ToastOptions) => {
  return (
    <div className="sm:block flex justify-center py-[100px] bg-background-dark text-text-primary-light">
      <div className="w-full max-w-full xl:max-w-[1450px] mx-auto px-4 sm:px-12 lg:px-18 xl:px-32">
        <div className="inline-flex items-center mb-8">
          <img
            src={money_plus_safety}
            className="p-1 md:p-0 h-14"
            alt="money plus safety"
          />
        </div>

        <div
          className="font-sfPro font-medium text-[26px] md:text-[48px] leading-[31.03px] md:leading-[62px]"
        >
          When your money is safe, a new world of limitless trade opportunities
          is opened.
          <span className="text-text-secondary">
            You can buy anything from anyone and pay anyone for any service.
          </span>
        </div>
        <button onClick={()=> window.open("https://medium.com/paycrows-blog/introducing-paycrow-ab2d037f8e86", "_blank")} className="text-sm md:text-xl stroke-white hover:stroke-background-dark h-[56px] py-[14px] px-[24px] md:h-[62px] md:px-[24px] mt-8 bg-transparent border border-white text-white rounded-full inline-flex items-center hover:bg-white hover:text-background-dark">
          <span>Learn about how escrow works</span>
          <svg
            className="w-2 h-2 ml-2"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M13 1H1M13 1V13.5M13 1L1 13" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default DetailSection;
