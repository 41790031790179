
import React from 'react';

export default function Twitter(props: any) {
    return(
        <svg width="40" height="40" viewBox="0 0 40 40" fill="current" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.9992 11.7015L22.8869 11.6978C19.3904 11.6978 17.1308 13.4365 17.1308 16.1276V18.17H14.0016C13.7312 18.17 13.5122 18.3344 13.5122 18.5372V21.4965C13.5122 21.6993 13.7314 21.8635 14.0016 21.8635H17.1308V29.3307C17.1308 29.5335 17.3498 29.6978 17.6202 29.6978H21.703C21.9734 29.6978 22.1923 29.5333 22.1923 29.3307V21.8635H25.8512C26.1216 21.8635 26.3405 21.6993 26.3405 21.4965L26.342 18.5372C26.342 18.4399 26.2903 18.3466 26.1987 18.2777C26.1071 18.2088 25.9822 18.17 25.8524 18.17H22.1923V16.4386C22.1923 15.6064 22.4567 15.184 23.9021 15.184L25.9987 15.1834C26.2689 15.1834 26.4878 15.019 26.4878 14.8164V12.0685C26.4878 11.8661 26.2691 11.7019 25.9992 11.7015Z" fill="current"/>
        </svg>
    )
}

